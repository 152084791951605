export const XXL = '25px';
export const XL = '20px';
export const L = '18px';
export const M = '16px';
export const S = '14px';
export const XS = '12px';
export const XXS = '11px';

export const bold = 700;
export const semibold = 600;
export const regular = 400;
