import React, { useState } from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import { mediaQueries, Text, Spacing } from '@reservamos/elements';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { css, useTheme } from '@emotion/react';
import LayoutContainer from '../../atoms/LayoutContainer';
import { firstLayer } from '../../../src/tokens/zindexes';
import assetUrl from '../../../utils/assetUrl';
import {
  sprinterAmenities,
  busAmenities,
} from '../../../constants/sliderAmenities';

const SliderRB = () => {
  const [nav1, setNav1] = useState(null);

  const { colors } = useTheme();

  const buttonStyles = css`
    height: 30px;
    width: 30px;
    ${mediaQueries.small} {
      height: 20px;
      width: 20px;
    }
  `;

  const wrapper = css`
    background: url(${assetUrl('main/bg-slider.png')});
    background-size: cover;
    padding: 30px 0;
    border-bottom: 4px solid ${colors.primary};

    ${mediaQueries.small} {
      padding: 10px 0;
    }

    button::before,
    button::after {
      opacity: 0.8;
      font-size: 30px;
      color: ${colors.primary};

      ${mediaQueries.small} {
        font-size: 20px;
      }
    }

    .slick-prev {
      left: 10px;
      z-index: ${firstLayer};
      ${buttonStyles};
    }

    .slick-next {
      right: 20px;
      ${buttonStyles};
      ${mediaQueries.small} {
        right: 1px;
      }
    }
  `;

  const imageWrapper = css`
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;

    ${mediaQueries.small} {
      max-height: 200px;
      min-height: 200px;
    }
  `;

  const navSlider = css`
    max-height: 30px;
    div {
      max-width: 40px;
    }

    button {
      visibility: hidden;
    }
  `;

  const navSliderDot = css`
    background-color: blue;
    height: 20px;
    width: 20px;
    max-width: 20px;
    border-radius: 50px;
  `;

  return (
    <div css={wrapper}>
      <LayoutContainer paddingMobile>
        <Spacing vertical size="L">
          <Text color="grayStrong" size="XXL" weight="bold">
            Conoce nuestras unidades
          </Text>
          <Slider asNavFor={nav1} ref={(slider) => setNav1(slider)}>
            <div>
              <Spacing size="XL" flexGrow isResponsive>
                <div css={imageWrapper}>
                  <Image
                    src={assetUrl('main/mercedez-benz.webp')}
                    width={465}
                    height={300}
                    alt="Mercedez Benz"
                  />
                </div>
                <Spacing vertical size="L">
                  <Text size="XXL" color="primary" weight="bold">
                    Camioneta Sprinter Mercedez Benz
                  </Text>
                  <ul>
                    {sprinterAmenities.map((amenity) => (
                      <li key={amenity}>
                        <Text>{amenity}</Text>
                      </li>
                    ))}
                  </ul>
                </Spacing>
              </Spacing>
            </div>
            <div>
              <Spacing size="XL" flexGrow isResponsive>
                <div css={imageWrapper}>
                  <Image
                    src={assetUrl('main/irizar-16s.webp')}
                    width={465}
                    height={300}
                    alt="Irizar 16s"
                  />
                </div>
                <Spacing vertical size="L">
                  <Text size="XXL" color="primary" weight="bold">
                    Autobus Irizar 16s
                  </Text>
                  <ul>
                    {busAmenities.map((amenity) => (
                      <li key={amenity}>
                        <Text>{amenity}</Text>
                      </li>
                    ))}
                  </ul>
                </Spacing>
              </Spacing>
            </div>
          </Slider>
          <Slider
            asNavFor={nav1}
            ref={(slider) => setNav1(slider)}
            slidesToShow={1}
            swipeToSlide
            focusOnSelect
            css={navSlider}
          >
            <div css={navSliderDot} />
            <div css={navSliderDot} />
          </Slider>
        </Spacing>
      </LayoutContainer>
    </div>
  );
};

export default SliderRB;
