import React from 'react';
import Image from 'next/image';
import { Spacing, Text } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import LayoutContainer from '../../atoms/LayoutContainer';
import assetUrl from '../../../utils/assetUrl';

const DescriptionRB = () => {
  const { colors } = useTheme();
  const background = css`
    background-image: url(${assetUrl('main/background.jpg')});
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    min-height: 650px;
    padding: 25px;
    margin: 0px;
  `;
  const textContainer = css`
    background-color: ${colors.white};
    width: 300px;
    border-radius: 30px 0 0 0;
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  `;
  const img = css`
    box-shadow: 2px 2px 5px ${colors.gray300};
    max-width: 100%;
    height: 74px;
  `;
  const divider = css`
    border: 1px solid ${colors.gray300};
    width: 100%;
  `;
  return (
    <div css={background}>
      <LayoutContainer>
        <Spacing vertical size="L">
          <Text weight="bold" size="XXL" color="white">
            ¿Por qué viajar con roll & bits?
          </Text>
          <Spacing size="L" isResponsive flexGrow mobileAlign="center">
            <div css={textContainer}>
              <div css={img}>
                <Image
                  src={assetUrl('main/busqueda.png')}
                  width={280}
                  height={100}
                  alt="Busqueda de destino"
                />
              </div>
              <Text weight="bold" size="L" color="grayMedium">
                Encuentra tu destino
              </Text>
              <hr css={divider} />
              <Text size="S" color="grayMedium" lineHeight="1.8">
                Viajar a Ciudad de México, Tepotzotlán, Guadalajara, Querétaro y
                León nunca fue tan fácil. Con ayuda de nuestro buscador consulta
                fechas y horarios en los que necesites viajar, y adquiere
                boletos con hasta 90 días de anticipación.
              </Text>
            </div>
            <div css={textContainer}>
              <div css={img}>
                <Image
                  src={assetUrl('main/asiento.png')}
                  width={280}
                  height={100}
                  alt="asientos"
                />
              </div>
              <Text weight="bold" size="L" color="grayMedium">
                Selecciona tu asiento
              </Text>
              <hr css={divider} />
              <Text size="S" color="grayMedium" lineHeight="1.8">
                Elige tu asiento favorito al momento de hacer tu compra.
                Contamos con unidades de 17 asientos tipo sprinter, además de
                contar con un autobus de 30 asientos unico en el centro de país.
              </Text>
            </div>
            <div css={textContainer}>
              <div css={img}>
                <Image
                  src={assetUrl('main/viaje.png')}
                  width={280}
                  height={100}
                  alt="ruta"
                />
              </div>
              <Text weight="bold" size="L" color="grayMedium">
                Viaja cómodo y seguro
              </Text>
              <hr css={divider} />
              <Text size="S" color="grayMedium" lineHeight="1.8">
                Al viajar con Roll & Bits lo haces en unidades monitoreadas
                durante todo el viaje vía GPS, aire acondicionado, y un sistema
                de streaming único de nuestra marca. Además de viajar con un
                seguro de viajero durante todo tu traslado, y en compañía de
                conductores capacitados.
              </Text>
            </div>
          </Spacing>
        </Spacing>
      </LayoutContainer>
    </div>
  );
};

export default DescriptionRB;
