import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spacing } from '@reservamos/elements';
import Autocomplete from '../ui/molecules/Autocomplete';

const SearchModal = ({
  modalTrigger,
  setFieldValue,
  placeholder,
  inputName,
  inputDisplayName,
  originSelectedValue,
  openSelect,
}) => {
  return (
    <Modal
      modalTrigger={modalTrigger}
      responsiveSize="M"
      closeIcon="ClosePrimary"
      titleColor="primaryStrong"
    >
      {({ closeModal }) => {
        const onItemSelect = (item) => {
          closeModal();
          setFieldValue(inputName, item.slug);
          setFieldValue(inputDisplayName, item.name);
        };

        return (
          <Spacing size="L" vertical>
            <Autocomplete
              iconInput="vanda/icons/departure-gray.svg"
              placeholder={placeholder}
              onItemSelect={onItemSelect}
              requestParams={originSelectedValue}
              openSelect={openSelect}
            />
          </Spacing>
        );
      }}
    </Modal>
  );
};

SearchModal.propTypes = {
  modalTrigger: PropTypes.node.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputDisplayName: PropTypes.string.isRequired,
  originSelectedValue: PropTypes.string,
  openSelect: PropTypes.bool,
};

SearchModal.defaultProps = {
  originSelectedValue: '',
  openSelect: false,
};

export default SearchModal;
