import React from 'react';
import { Spacing, Text, mediaQueries, Visibility } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import LayoutContainer from '../../atoms/LayoutContainer';
import Header from '../Header';
import SearchWidget from '../../organisms/SearchWidget';
import assetUrl from '../../../utils/assetUrl';

const HeaderRB = ({ title, message }) => {
  const { colors } = useTheme();
  const background = css`
    background-image: url(${assetUrl('main/header.webp')});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    width: 100%;
    background-position: center center;
    margin-top: 65px;
    display: flex;
    align-items: flex-end;
    padding: 70px 30px;
    ${mediaQueries.small} {
      background: ${colors.primary};
      padding: 30px 10px;
    }
  `;

  const bottomContent = css`
    background-color: ${colors.accent};
    margin: 0px;
    padding: 20px;
    display: flex;
    justify-content: center;
  `;

  return (
    <div>
      <Header
        alt="Vanda Logo"
        logoImage={assetUrl('main/logo.webp')}
        hasBorder
        hasPhone={false}
      />
      <div css={background}>
        <LayoutContainer>
          <Spacing vertical>
            <Visibility type="showForMobileOnly">
              <Text
                weight="bold"
                size="XXL"
                color="white"
                mobileSize="M"
                textAlign="center"
              >
                {title}
              </Text>
            </Visibility>
            <SearchWidget />
          </Spacing>
        </LayoutContainer>
      </div>
      <div css={bottomContent}>
        <Text color="white" size="S">
          {message}
        </Text>
      </div>
    </div>
  );
};
HeaderRB.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default HeaderRB;
