import React from 'react';
import { Spacing } from '@reservamos/elements';
import { useTranslation } from 'next-i18next';
import LayoutContainer from '../ui/atoms/LayoutContainer';
import HeaderRB from '../ui/molecules/RollBits/HeaderRB';
import DescriptionRB from '../ui/molecules/RollBits/DescriptionRB';
import BenefitsRB from '../ui/molecules/RollBits/BenefitsRB';
import FooterRB from '../ui/molecules/RollBits/FooterRB';
import SliderRB from '../ui/molecules/RollBits/SliderRB';
import Events from '../ui/molecules/RollBits/Events';

const HomeRollBits = () => {
  const { t } = useTranslation('home');

  return (
    <>
      <Spacing vertical>
        <HeaderRB
          title={t('title', { context: process.env.NEXT_PUBLIC_BRAND })}
          message={t('message')}
        />
        <Spacing vertical size="L" responsiveSize="M">
          <LayoutContainer paddingMobile>
            <Spacing vertical size="L" responsiveSize="M">
              <Events title={t('events')} />
              <BenefitsRB />
            </Spacing>
          </LayoutContainer>
          <SliderRB />
          <DescriptionRB />
        </Spacing>
      </Spacing>
      <FooterRB />
    </>
  );
};

export default HomeRollBits;
