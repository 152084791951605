export const sprinterAmenities = [
  '17 asientos',
  'Servicio de contenido Multimedia en Streaming',
  'Aire acondicionado',
  'Conexiones USB',
  'Seguro de viajero',
  'Cajuela',
  'Wifi',
  'Cinturón de seguridad',
  'Conexiones eléctricas',
];

export const busAmenities = [
  '30 asientos',
  'Servicio de contenido Multimedia en Streaming',
  'Aire acondicionado',
  'Conexiones USB',
  'Seguro de viajero',
  'Cajuela',
  'Wifi',
  'Cinturón de seguridad',
  'Conexiones eléctricas',
];
