import { useEffect, useState } from 'react';

const Breakpoint = {
  small: 374,
  medium: 640,
  large: 1080,
};

function handleElementQueries(windowWidth) {
  let size;
  if (windowWidth <= Breakpoint.small) {
    size = 'xsmall';
  }

  if (windowWidth > Breakpoint.small && windowWidth <= Breakpoint.medium) {
    size = 'small';
  }

  if (windowWidth > Breakpoint.medium && windowWidth <= Breakpoint.large) {
    size = 'medium';
  }

  if (windowWidth > Breakpoint.large) {
    size = 'large';
  }

  return size;
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const size = handleElementQueries(windowWidth);
  return {
    size,
    isMobile: ['xsmall', 'small'].includes(size),
    isDesktop: ['medium', 'large'].includes(size),
  };
}

export default useWindowSize;
