import React, { useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import HomeVanda from '../components/HomeVanda';
import HomeRollBits from '../components/HomeRollBits';
import * as mixpanel from '../lib/mixpanel';

const Home = () => {
  const router = useRouter();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
      router.push('/maintenance');
    }
  }, [router]);

  // TODO: Init mixpanel in a better way to avoid this
  useEffect(() => {
    const timeout = setTimeout(() => {
      mixpanel.track('Interest In Home');
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (process.env.NEXT_PUBLIC_BRAND !== 'vanda') return <HomeRollBits />;
  return <HomeVanda />;
};

export const getStaticProps = async () => ({
  props: {
    ...(await serverSideTranslations('es', ['common', 'home'])),
  },
});

export default Home;
