import React from 'react';
import PropTypes from 'prop-types';
import { Text, mediaQueries } from '@reservamos/elements';
import { css } from '@emotion/react';
import DescriptionIcon from './DescriptionIcon';
import Container from '../atoms/Container';

const HomeMarketingContent = ({ videoTitle, videoSrc }) => {
  const marketingVideo = css`
    iframe {
      border: 0;
      margin-right: 20px;
      border-radius: 10px;
      min-width: 500px;
      height: 100%;
    }
    ${mediaQueries.medium} {
      iframe {
        min-width: 739px;
    min-height: 200px;
      }
    ${mediaQueries.small} {
      iframe {
        margin-right: 0px;
        min-width: 100%;
        height: 200px;
      }
    }
  `;
  const iconContainer = css`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    ${mediaQueries.medium} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      overflow-x: scroll;
      display: flex;
      margin-top: 20px;
    }
  `;
  const container = css`
    display: flex;
    max-height: 450px;

    ${mediaQueries.medium} {
      flex-direction: column;
    }
  `;
  const text = css`
    padding-bottom: 10px;
  `;

  return (
    <Container>
      <div css={text}>
        <Text color="primaryStrong" weight="semibold" size="L">
          ¿Por qué viajar en Vanda?
        </Text>
      </div>
      <div css={container}>
        <div css={marketingVideo}>
          <iframe title={videoTitle} src={videoSrc} allowFullScreen />
        </div>
        <div css={iconContainer}>
          <div>
            <DescriptionIcon
              isCentered
              icon="vanda/icons/mkt/mkt-1.svg"
              title="Viajes baratos"
              hasWidth
            >
              <Text size="S" textAlign="center">
                Encuentra <strong>el mejor precio</strong> para viajar
              </Text>
            </DescriptionIcon>
          </div>
          <div>
            <DescriptionIcon
              isCentered
              icon="vanda/icons/mkt/mkt-5.svg"
              title="Descuentos grupales"
              hasWidth
            >
              <Text size="S" textAlign="center">
                Conoce nuestras promociones y viaja ahorrando.
              </Text>
            </DescriptionIcon>
          </div>

          <div>
            <DescriptionIcon
              isCentered
              icon="vanda/icons/mkt/mkt-3.svg"
              title="Cobertura Vanda"
              hasWidth
            >
              <Text size="S" textAlign="center">
                Viaja con seguridad y tranquilidad durante el trayecto.
              </Text>
            </DescriptionIcon>
          </div>
          <div>
            <DescriptionIcon
              isCentered
              icon="vanda/icons/mkt/mkt-4.svg"
              title="Práctico"
              hasWidth
            >
              <Text size="S" textAlign="center">
                Evita aglomeraciones. En Vanda contamos con puntos de salida y
                llegada más accesibles que una terminal.
              </Text>
            </DescriptionIcon>
          </div>
        </div>
      </div>
    </Container>
  );
};

HomeMarketingContent.propTypes = {
  videoSrc: PropTypes.string,
  /**  for video */
  videoTitle: PropTypes.string,
};

HomeMarketingContent.defaultProps = {
  videoSrc: '',
  videoTitle: '',
};

export default HomeMarketingContent;
