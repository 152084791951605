import React from 'react';
import PropTypes from 'prop-types';
import ImageCard from './ImageCard';
import activeRoutes from '../../constants/activeRoutes';

const CardsTripsFull = ({ isHome }) => {
  const routesToShow = isHome
    ? activeRoutes.filter((route) => route.isHome === isHome)
    : activeRoutes;

  return routesToShow.map(({ departure, arrival, price, imageSrc, href }) => {
    return (
      <ImageCard
        departure={departure}
        arrival={arrival}
        price={price}
        imageSrc={imageSrc}
        href={href}
        fullImage
      />
    );
  });
};

CardsTripsFull.propTypes = {
  isHome: PropTypes.bool,
};

CardsTripsFull.defaultProps = {
  isHome: false,
};

export default CardsTripsFull;
