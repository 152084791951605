import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { css, useTheme } from '@emotion/react';
import { Spacing, mediaQueries, Text, Visibility } from '@reservamos/elements';
import { firstLayer } from '../../../src/tokens/zindexes';
import specialEvents from '../../../constants/destinationsRB';

export default function SyncSlider() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const { colors } = useTheme();

  const buttonStyles = css`
    height: 30px;
    width: 30px;
    ${mediaQueries.small} {
      height: 20px;
      width: 20px;
    }
  `;

  const arrow = css`
  padding: 15px 0;

  ${mediaQueries.small} {
    button::before,
    button::after {
      opacity: 0;
  }  
  `;
  const wrapper = css`
    padding: 30px 0;
    max-width: 100%;

    ${mediaQueries.small} {
      padding: 20px 0;
      max-width: 100%;
    }

    button::before,
    button::after {
      opacity: 0.8;
      font-size: 30px;
      color: ${colors.primary};

      ${mediaQueries.small} {
        font-size: 20px;
      }
    }

    .slick-prev {
      left: 0px;
      z-index: ${firstLayer};
      ${buttonStyles};
    }

    .slick-next {
      right: 0px;
      ${buttonStyles};
      ${mediaQueries.small} {
        right: 1px;
      }
    }
  `;

  const image = css`
    width: 90%;

    &:hover {
      box-shadow: 0px 0px 10px ${colors.gray200};
      transition: all ease-in-out 0.2s;
    }

    ${mediaQueries.medium} {
      width: 100%;
      // max-height: 100%;
    }
  `;

  const image2 = css`
    max-width: 350px;
    height: 200px;

    &:hover {
      box-shadow: 0px 0px 10px ${colors.gray200};
      transition: all ease-in-out 0.2s;
    }

    ${mediaQueries.medium} {
      max-width: 154px;
      max-height: 154px;
    }
    ${mediaQueries.small} {
      max-width: 85px;
      max-height: 85px;
    }
  `;

  // TODO: Identify why is this causing to render incorrectly some images
  // const activeEvents = specialEvents.filter((event) => event.isActive);

  return (
    <div css={wrapper}>
      <Slider autoplay asNavFor={nav2} ref={slider1}>
        {specialEvents.map(({ href, banner, alt, descriptionText }) => (
          <Spacing alignItems="center" justifyContent="center" key={banner}>
            <a href={href} target="_blank" rel="noreferrer">
              <Spacing vertical alignItems="center">
                <img src={banner} alt={alt} css={image} />
                <Text weight="semibold" textAlign="center" size="S">
                  {descriptionText}
                </Text>
              </Spacing>
            </a>
          </Spacing>
        ))}
      </Slider>
      <div css={arrow}>
        <Slider
          asNavFor={nav1}
          ref={slider2}
          slidesToShow={4}
          swipeToSlide
          focusOnSelect
        >
          {specialEvents.map(({ href, img, alt, descriptionText }) => (
            <Spacing alignItems="center" justifyContent="center" key={img}>
              <a href={href} target="_blank" rel="noreferrer">
                <Spacing vertical alignItems="center">
                  <img src={img} alt={alt} css={image2} />
                  <Visibility type="hideForMobileOnly">
                    <Text
                      weight="semibold"
                      textAlign="center"
                      size="S"
                      mediumSize="XS"
                    >
                      {descriptionText}
                    </Text>
                  </Visibility>
                </Spacing>
              </a>
            </Spacing>
          ))}
        </Slider>
      </div>
    </div>
  );
}
