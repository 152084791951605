import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import SyncSlider from './SyncSlider';

const Events = ({ title }) => {
  return (
    <Spacing vertical size="L">
      <Text weight="bold" size="XXL">
        {title}
      </Text>
      <Spacing isResponsive alignItems="center">
        <SyncSlider isSlider />
      </Spacing>
    </Spacing>
  );
};

Events.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Events;
