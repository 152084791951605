import React from 'react';
import { mediaQueries, Spacing } from '@reservamos/elements';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const SliderItem = ({ hasGif, backImage, leftImage, rightImage, src, alt }) => {
  const imageHalf = css`
    max-width: 300px;
    padding: 5px;
    ${mediaQueries.small} {
      max-width: 145px;
    }
  `;

  const back = css`
    background-image: url(${backImage});
    background-size: 100%;
  `;

  const imageFull = css`
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    ${mediaQueries.medium} {
      height: 300px;
      overflow: hidden;
    }
    ${mediaQueries.small} {
      height: 145px;
      overflow: hidden;
    }
  `;

  return (
    <>
      {hasGif ? (
        <div css={back}>
          <Spacing
            size="XXS"
            flexGrow
            alignItems="center"
            justifyContent="center"
          >
            <img key={leftImage} src={leftImage} alt={alt} css={imageHalf} />
            <img key={rightImage} src={rightImage} alt={alt} css={imageHalf} />
          </Spacing>
        </div>
      ) : (
        <div>
          <img src={src} alt={alt} css={imageFull} />
        </div>
      )}
    </>
  );
};

SliderItem.propTypes = {
  hasGif: PropTypes.bool,
  backImage: PropTypes.string,
  leftImage: PropTypes.string,
  rightImage: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
};

SliderItem.defaultProps = {
  hasGif: false,
  backImage: '',
  leftImage: '',
  rightImage: '',
  src: '',
  alt: '',
};

export default SliderItem;
