import gql from 'graphql-tag';

export default gql`
  query GetMeetingPoints($from: String) {
    meetingPoints(from: $from) {
      name
      slug
    }
  }
`;
