import React from 'react';
import { Icon, Spacing, Text } from '@reservamos/elements';
import { css } from '@emotion/react';
import assetUrl from '../../../utils/assetUrl';

const BenefitsRB = () => {
  const row = css`
    border-bottom: 4px solid #ff605a;
    padding-bottom: 20px;
  `;
  return (
    <Spacing vertical size="XL">
      <div css={row}>
        <Text weight="bold" size="XXL">
          Beneficios
        </Text>
      </div>
      <div css={row}>
        <Spacing flexGrow isResponsive>
          <Spacing vertical alignItems="center">
            <Icon type={assetUrl('icons/tiempo.png')} size="XXL" />
            <Text weight="bold">Ahorra tiempo y dinero</Text>
            <Text size="S" color="grayMedium">
              Contamos con puntos de salida y llegada más cercanos a ti, sin la
              necesidad de tocar centrales de autobuses.
            </Text>
          </Spacing>
          <Spacing vertical alignItems="center">
            <Icon type={assetUrl('icons/seguro.png')} size="XXL" />
            <Text weight="bold">Seguro de viajero</Text>
            <Text size="S" color="grayMedium">
              Roll & bits te ofrece conductores capacitados, GPS en las
              camionetas y Seguro de Viajero. Así como camaras de seguridad en
              el interior de nuestras unidades.
            </Text>
          </Spacing>
          <Spacing vertical alignItems="center">
            <Icon type={assetUrl('icons/factura.png')} size="XXL" />
            <Text weight="bold">Factura de viaje</Text>
            <Text size="S" color="grayMedium">
              Todos tus boletos de roll & bits podrán ser facturados, solo da
              clic en este link y sigue las instrucciones.
            </Text>
          </Spacing>
          <Spacing vertical alignItems="center">
            <Icon type={assetUrl('icons/ambiente.png')} size="XXL" />
            <Text weight="bold">Cuida el medio ambiente</Text>
            <Text size="S" color="grayMedium">
              Disminuyendo la cantidad de vehículos que viajan con baja
              ocupación.
            </Text>
          </Spacing>
        </Spacing>
      </div>
    </Spacing>
  );
};
BenefitsRB.propTypes = {};

export default BenefitsRB;
