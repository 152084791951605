import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import spanishLocale from 'date-fns/locale/es';
import intervalToDuration from 'date-fns/intervalToDuration';
import isPast from 'date-fns/isPast';
import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import { parse } from 'date-fns';

export function formatDateLong(date) {
  return format(new Date(date), 'PPPP', {
    locale: spanishLocale,
  });
}

export function formatDateShort(date) {
  return format(new Date(date), 'dd MMMM', {
    locale: spanishLocale,
  });
}

export function getDeltaInDays(departureDate) {
  const delta = intervalToDuration({
    start: new Date(),
    end: new Date(departureDate),
  });

  return delta?.days;
}

export function customFormat(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function getRelativeDays(date, isTomorrow) {
  const parsedDate = new Date(date.replace(/-/g, '/'));
  const dayToHandle = isTomorrow
    ? addDays(new Date(parsedDate), 1)
    : subDays(new Date(parsedDate), 1);
  const formattedDay = customFormat(dayToHandle);
  return formattedDay;
}

export function getDayComplete(date) {
  const parsedDate = new Date(date.replace(/-/g, '/'));
  const dayToHandle = formatDateShort(parsedDate);
  return dayToHandle;
}

export function getIsPastDate(date) {
  const parsedDate = new Date(date.replace(/-/g, '/'));
  const endDay = endOfDay(parsedDate);
  return isPast(endDay);
}

export function getTimeWithPeriod(time) {
  const [hours, minutes] = time.split(':');
  const hoursNum = parseInt(hours, 10);
  const period = hoursNum < 12 ? 'a.m' : 'p.m';
  const formattedTime = `${hours}:${minutes}`;
  return `${formattedTime} ${period}`;
}

export function parseDateTime(dateTimeString) {
  const parsedDate = parseISO(dateTimeString);
  const formattedDate = format(parsedDate, "d 'de' MMMM yyyy H:mm a", {
    locale: spanishLocale,
  });
  return formattedDate;
}

// Format date from 01-Ene-21 to 2021-01-01 to work
// with funnel dates on this project
export function formatFunnelDate(dateString) {
  const parsedDate = parse(dateString, 'dd-MMM-yy', new Date(), {
    locale: spanishLocale,
  });
  return format(parsedDate, 'yyyy-MM-dd');
}
