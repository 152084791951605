const awsURL = process.env.NEXT_PUBLIC_AWS_URL;

const activeRoutes = [
  {
    departure: 'Ciudad de México',
    arrival: 'Guadalajara',
    price: '99',
    imageSrc: `${awsURL}/gdl.jpeg`,
    href: '/trips?originCity=ciudad-de-mexico&destinationCity=guadalajara',
    isHome: true,
  },
  {
    departure: 'Guadalajara',
    arrival: 'Ciudad de México',
    price: '99',
    imageSrc: `${awsURL}/cdmx-2.jpg`,
    href: '/trips?originCity=guadalajara&destinationCity=ciudad-de-mexico',
    isHome: true,
  },
];

export default activeRoutes;
