import gql from 'graphql-tag';

export default gql`
  query GetCities($from: String) {
    cities(from: $from) {
      name
      slug
    }
  }
`;
